import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mobileMixin from './utils/mobileMixin';
import axios from 'axios'
import moment from 'moment';
//  =======  Dev depensies packages ======
import YmapPlugin from 'vue-yandex-maps';
import VueAwesomePaginate from "vue-awesome-paginate";
import ToastPlugin from 'vue-toast-notification';

// ======  Languages  ===========
import { createI18n } from 'vue-i18n';
import uz from './locales/uz';
import ru from './locales/ru';
import en from './locales/en';
const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'ru',
    messages: { uz, ru, en },
});

// ======  Font awesome icons  ===========
import { loadFonts } from './plugins/webfontloader'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSliders, faMagnifyingGlass, faCalendar, faHashtag, faRightFromBracket, faAddressCard, faChevronDown, faBars, faChevronUp, faClipboard, faMoon, faSun, faUser, faUsers, faXmark, faCircleDollarToSlot, faCoins, faWarehouse, faCircleXmark, faListCheck, faSackDollar, faLayerGroup, faHandHoldingDollar, faBarsProgress, faLeaf, faFolderOpen, faUsersGear, faChartSimple, faRecycle, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import { faGgCircle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faSliders, faHashtag, faMagnifyingGlass, faCalendar, faMoneyBillTrendUp, faRecycle, faChartSimple, faUsersGear, faFolderOpen, faLeaf, faBarsProgress, faHandHoldingDollar, faLayerGroup, faSackDollar, faListCheck, faCircleXmark, faWarehouse, faCoins, faCircleDollarToSlot, faGgCircle, faUsers, faClipboard, faUser, faAddressCard, faRightFromBracket, faXmark, faChevronDown, faChevronUp, faBars, faSun, faMoon);
loadFonts()

// ======  Font awesome icons  ===========
import './assets/styles/login.scss'
import './assets/styles/main.scss'
import './assets/styles/sidebar.scss'
import './assets/styles/navbar.scss'
import './assets/styles/clients.scss'
import './assets/styles/responsive.scss'
import './assets/styles/roll-calls.scss'
import './assets/styles/tasks.scss'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "vue-awesome-paginate/dist/style.css";

//  ====== Axios token settings
axios.interceptors.request.use(function(config) {
    const token = store.getters.authToken
    if (token != null) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function(err) {
    return Promise.reject(err)
})

//  ==== Axios base url set
const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL
    })
    //  ====  Yandex Map settings
const settings = {
    apiKey: '31cf98cc-83b1-406a-9722-daae627b253f',
    coordorder: 'latlong',
    debug: true,
    lang: 'ru_RU',
};

const token = JSON.parse(localStorage.getItem('token'))
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}
moment.locale('ru');


import Filters from './components/Filters.vue'
import Table from './components/Table.vue'
import BaseModal from './components/Modals/BaseModal.vue'
import Checkbox from './components/Checkbox.vue'
import Pagination from './components/Pagination.vue'

createApp(App)
    .component("Filters", Filters)
    .component("BaseModal", BaseModal)
    .component("Table", Table)
    .component("Checkbox", Checkbox)
    .component("Pagination", Pagination)
    .provide('axios', axiosInstance)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mixin(mobileMixin)
    .use(YmapPlugin, settings)
    .use(i18n)
    .use(router)
    .use(store)
    .use(ToastPlugin)
    .use(vuetify)
    .provide(moment)
    .use(VueAwesomePaginate)
    .mount('#app')