export default {
    longitude: 'Долгота',
    latitude: 'Широта',
    history_work: 'История выхода на работу',
    login: 'Войти',
    search: 'Поиск',
    date: 'Дата',
    time: 'Время',
    history: 'История',
    close: 'Закрыть',
    roll_call_status: 'Ваш ответ успешно сохранен',
    reason_title: 'Название причины',
    save_as: 'Сохранить как',
    write_reason: 'Укажите причину',
    save: 'Сохранить',
    right: 'Верно',
    location: 'Локация',
    no_call: 'Нет активных переклички',
    all_tasks: 'Все задачи',
    completed_tasks: 'Выполненные',
    clear: "Сбросить",
    executor: 'Исполнитель',
    title: 'Название',
    category: 'Категория',
    no_data: 'Нет данных по запросу',
    requiredField: 'Обязательное поле для заполнение',
    taskCreated: 'Задача успешно создана',
    profile: {
        profile: 'Профиль',
        firstname: 'Фамилия:',
        lastname: 'Имя:',
        phone: 'Номер телефона:',
        status: 'Должность:',
        username: 'Имя пользователя:',
        logout: 'Выйти'

    },
    sidebar: {
        account_management: 'Управление счетам',
        dashboard: 'Панель',
        clients: 'Клиенты',
        currency_accounts: 'Валютные счета',
        finance: 'Финансы',
        warehouses: 'Склады',
        products: 'Продукты',
        raw_materials: 'Cырье',
        reports: 'Отчёты',
        debts: 'Пользовательские кредиты',
        salary: 'Зарплата',
        orders: 'Заказы',
        employees: 'Сотрудники',
        tasks: 'Задачи',
        outcome: 'Расходы',
        income: 'Доходы',
        credit_line: 'Кредитные линиии',
        loan_repayments: 'Погашение кредита',
        roll_call: 'Перекличка',
        profile: 'Профиль'
    },
    table: {
        num: '№',
        time: 'Время',
        open: 'Открыта',
        business_line: 'Бизнес линия',
        fullname: 'Ф.И.О',
        company: 'Компания',
        phone_number: 'Номер телефона',
        address: 'Адрес',
        title: 'Название',
        title_product: 'Название продукты',
        category: 'Категория',
        identifier: 'Выдающее лицо',
        recipient: 'Получатель',
        measurement: 'Единицы измерения',
        responsible: 'Ответственный',
        location: 'Локация',
        showmap: 'Показать на карте',
        nodata: 'Нет данных',
        branch_office: 'Филиалы',
        job_title: 'Должность',
        peace: 'Штук/Блок',
        amount: 'Количество',
        notification: 'Мин. к-во для оповещений',
        created_date: 'Дата создание',
        end_date: 'Дата окончания',
        task: 'Задача',
        event: 'Действия',
        create: 'Добавить',
        sum: 'Сумма',
        date: 'Дата',
        comment: 'Комментарий',
        status: 'Статус',
        photo: 'Фото',
        username: 'Имя пользователя:',
        warehouse: 'Склад',
        excess_allowed: 'Допускается превышение',
        shelf_life: 'Срок хранения(дней)',
        currency: 'Валюта',
        return_date: 'Дата возврата',
        repaid: 'Погашенный',
        reason: 'Причина',
        add_income: 'Добавить доход',
        currency_account: 'Валютный счет'
    }
}