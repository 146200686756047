<template>
  <div class="round">
    <input
      type="checkbox"
      :id="checkboxId"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label :for="checkboxId">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    checkboxId: {
      // Unique identifier for the checkbox
      type: String,
      required: true,
    },
    label: {
      // Text label for the checkbox
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"], // Explicitly define emitted events
};
</script>

<style scoped>
.round {
  position: relative;
}

.round label {
  border: 1px solid rgb(var(--v-theme-iconColor));
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
</style>
