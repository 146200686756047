// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
const customDarkTheme = {
    dark: true,
    colors: {
        frozenBack: "rgba(255, 255, 255, 0.10)",
        iconColor: '#fff',
        navbarIconColor: "#fff",
        sidebarColor: "#283046",
        tableColor: "#283046",
        theadColor: "#283046",
        trDark: "#242b3d",
        background: "#15202b",
        surface: "#15202b",
        primary: "#3f51b5",
        secondary: "#03dac6",
        error: "#f44336",
        info: "#2196F3",
        success: "#4caf50",
        warning: "#fb8c00",
        burger_menu: "#ffff",
        borderColor: "#293044",
        darkBorderColor: "#293044",
        modalColor: "#283046",
        buttonColor: "#32394D",
        taskStatusColor: "#283046",
        error: "#FF0000"
    },
};
const customLightTheme = {
    dark: false,
    colors: {
        frozenBack: "rgba(0, 0, 0, 0.05)",
        navbarIconColor: "#1C416A",
        iconColor: '#6e6b7b',
        sidebarColor: '#1C416A',
        navbarColor: '#ffff',
        tableColor: '#ffff',
        theadColor: '#F3F2F7',
        trDark: '#ffff',
        background: "#F4F5F7",
        surface: "#ffff",
        primary: "#3f51b5",
        secondary: "#00ccff",
        error: "#ffcc00",
        borderColor: "#e8e9eb",
        modalColor: "#ffff",
        buttonColor: "#1C416A",
        taskStatusColor: "#E8E9EA",
        error: "#FF0000"


    },
};
export default createVuetify({
    theme: {
        defaultTheme: "customLightTheme",
        themes: {
            customDarkTheme,
            customLightTheme,
        },
    },
})