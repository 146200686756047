<template>
  <div class="filters">
    <v-text-field
      prepend-inner-icon="mdi-magnify"
      variant="outlined"
      :placeholder="$t('title')"
      v-model="filterTitle"
    ></v-text-field>
    <v-select
      clearable
      chips
      item-title="fullname"
      item-value="user_id"
      prepend-inner-icon="mdi-account"
      :placeholder="$t('executor')"
      :items="users"
      v-model="filterUsers"
      variant="outlined"
    ></v-select>
    <v-text-field
      clearable
      type="date"
      :placeholder="$t('date')"
      v-model="selectedDate"
      variant="outlined"
    ></v-text-field>
    <v-select
      clearable
      prepend-inner-icon="mdi-pound"
      :placeholder="$t('category')"
      :items="category"
      v-model="filterTags"
      variant="outlined"
    ></v-select>
    <span @click="clearValues" class="filters__clear">{{ $t("clear") }}</span>
    <button @click.stop="$emit('createTask')" class="create-button bg-sidebarColor">
      + {{ $t("table.create") }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    users: Array,
    category: Array,
    search: String,
    selectedDate: String,
  },
  data() {
    return {
      selectedDate: null,
    };
  },
  computed: {
    filterTags: {
      get() {
        return this.$store.getters.getFilterTags;
      },
      set(value) {
        this.$store.commit("setFiltersTags", value);
      },
    },
    filterTitle: {
      get() {
        return this.$store.getters.getFilterTitle;
      },
      set(value) {
        this.$store.commit("setFiltersTitle", value);
      },
    },
    filterUsers: {
      get() {
        return this.$store.getters.getFilterUsers;
      },
      set(value) {
        this.$store.commit("setFiltersUsers", value);
      },
    },
  },
  methods: {
    clearValues() {
      this.$store.commit("setFiltersUsers", null);
      this.$store.commit("setFiltersTitle", null);
      this.$store.commit("setFiltersTags", null);
      this.selectedDate = null;
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  border-top: 1px solid rgb(var(--v-theme-borderColor));
  display: flex;
  padding-top: 1rem;
  height: 4.5rem;
  gap: 1.2rem;
  padding: 2rem;
  margin-top: 2rem;
  &__clear {
    margin-top: 0.5rem;
    opacity: 0.5;
    cursor: pointer;
  }
}
</style>
