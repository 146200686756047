<template>
  <div>
    <div class="header">
      <div v-if="stasuses" class="filters">
        <v-text-field
          :label="$t('search')"
          variant="outlined"
          v-model="searchQuery"
        ></v-text-field>
        <v-select
          clearable
          :label="$t('table.category')"
          variant="outlined"
          v-model="selectedStatus"
          :items="stasuses"
        ></v-select>
      </div>
    </div>
    <v-table v-if="!mobile" class="table" hide-default-footer>
      <thead class="bg-theadColor">
        <tr>
          <th class="text-left" v-for="header in headers" :key="header">
            {{ $t("table." + header) }}
          </th>
        </tr>
      </thead>
      <tbody class="clients__table__body">
        <slot name="tr"></slot>
      </tbody>
    </v-table>
    <!-- Mobile -->
    <div v-else class="table-mobile">
      <slot name="mobile"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ["headers", "tr", "title", "stasuses", "categories"],
  data() {
    return {
      selectedStatus: null,
    };
  },
};
</script>
