import { createStore } from 'vuex';
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        currency_accounts: null
    },
    getters: {
        currencyAccounts(state) {
            return state.currency_accounts
        }
    },
    mutations: {
        getCurrency(state, accounts) {
            state.currency_accounts = accounts;
            console.log(state.currency_accounts)
        }
    },
    actions: {
        loadCurrency({ commit }) {
            axios.get('api/v1/currency_accounts/currency_accounts').then(result => {
                commit('SAVE_USERS', result.data);
            }).catch(error => {
                throw new Error(`API ${error}`);
            });
        }
    },
}