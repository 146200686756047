import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: "/",
        redirect: "/login"
    },
    {
        path: '/dashboard',
        name: 'sidebar.dashboard',
        components: {
            default: () =>
                import ('../views/Dashboard/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/login',
        name: 'sidebar.login',
        component: () =>
            import ('../views/Login/index.vue')
    },
    {
        path: '/profile',
        name: 'sidebar.profile',
        components: {
            default: () =>
                import ('../views/Profile/index'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/roll-call',
        name: 'sidebar.roll_call',
        components: {
            default: () =>
                import ('../views/RollCalls/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/currency-accounts',
        name: 'sidebar.currency_accounts',
        components: {
            default: () =>
                import ('../views/Currency/CurrencyAccounts.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/finance/income',
        name: 'sidebar.income',
        components: {
            default: () =>
                import ('../views/Finance/Incomes.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue'),
        }
    },
    {
        path: '/finance/outcome',
        name: 'sidebar.outcome',
        components: {
            default: () =>
                import ('../views/Finance/Expenditures.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue'),
        }
    },
    {
        path: '/warehouse',
        name: 'sidebar.warehouses',
        components: {
            default: () =>
                import ('../views/Warehouse/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue'),
        },
    },
    {
        path: '/warehouse/income',
        name: 'sidebar.income',
        components: {
            default: () =>
                import ('../views/Warehouse/Incomes.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue'),
        },
    },
    {
        path: '/warehouse/outcome',
        name: 'sidebar.outcome',
        components: {
            default: () =>
                import ('../views/Warehouse/Expenditures.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue'),
        },
    },
    {
        path: '/clients',
        name: 'sidebar.clients',
        components: {
            default: () =>
                import ('../views/Clients/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/tasks',
        name: 'sidebar.tasks',
        components: {
            default: () =>
                import ('../views/Tasks/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/products',
        name: 'sidebar.products',
        components: {
            default: () =>
                import ('../views/Products/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/raw-materials',
        name: 'sidebar.raw_materials',
        components: {
            default: () =>
                import ('../views/RawMaterials/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/salary',
        name: 'sidebar.salary',
        components: {
            default: () =>
                import ('../views/Salary/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/debts',
        name: 'sidebar.debts',
        components: {
            default: () =>
                import ('../views/Debts/Debts.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/debts/credit-line',
        name: 'sidebar.credit_line',
        components: {
            default: () =>
                import ('../views/Debts/CreditLines.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/debts/loan-repayments',
        name: 'sidebar.loan_repayments',
        components: {
            default: () =>
                import ('../views/Debts/LoanRepayment.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/orders',
        name: 'sidebar.orders',
        components: {
            default: () =>
                import ('../views/Orders/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
    {
        path: '/employees',
        name: 'sidebar.employees',
        components: {
            default: () =>
                import ('../views/Employees/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
