export default {
    longitude: 'Bo‘ylam',
    latitude: 'Kenglik',
    history_work: 'Ishga chiqish tarixi',
    login: 'Kirish',
    search: 'Qidirish',
    date: 'Sana',
    time: 'Vaqt',
    history: 'Tarix',
    close: 'Yopish',
    roll_call_status: 'Sizning javobingiz muvaffaqiyatli saqlandi',
    reason_title: 'Sabab sarlavhasi',
    save_as: 'Qanday saqlash',
    write_reason: 'Sababni kiriting',
    save: 'Saqlash',
    right: 'To‘g‘ri',
    location: 'Joylashuv',
    no_call: 'Aktiv chiqish yo‘q',
    profile: {
        profile: 'Profil',
        firstname: 'Familiya:',
        lastname: 'Ism:',
        phone: 'Telefon raqami:',
        status: 'Lavozimi:',
        username: 'Foydalanuvchi nomi:',
        logout: 'Chiqish'
    },
    sidebar: {
        account_management: 'Hisoblar boshqaruvi',
        dashboard: 'Boshqaruv paneli',
        clients: 'Mijozlar',
        currency_accounts: 'Valyuta hisoblar',
        finance: 'Moliya',
        warehouses: 'Omborlar',
        products: 'Mahsulotlar',
        raw_materials: 'Asl materiallar',
        reports: 'Hisobotlar',
        debts: 'Qarzdorliklar',
        salary: 'Oylik',
        orders: 'Buyurtmalar',
        employees: 'Xodimlar',
        tasks: 'Vazifalar',
        outcome: 'Chiqimlar',
        income: 'Kirimlar',
        credit_line: 'Kredit liniyasi',
        loan_repayments: 'Kredit to‘lashlari',
        roll_call: 'Chiqish',
        profile: 'Profil'
    },
    table: {
        num: '№',
        time: 'Vaqt',
        open: 'Ochilgan',
        business_line: 'Biznes liniyasi',
        fullname: 'F.I.Sh.',
        company: 'Kompaniya',
        phone_number: 'Telefon raqami',
        address: 'Manzil',
        title: 'Nomi',
        title_product: 'Mahsulot nomi',
        category: 'Tur',
        identifier: 'Shaxs',
        recipient: 'Qabul qiluvchi',
        measurement: 'O‘lchov birligi',
        responsible: 'Mas’ul',
        location: 'Joylashuv',
        showmap: 'Xaritada ko‘rsatish',
        nodata: 'Ma’lumot yo‘q',
        branch_office: 'Filiallar',
        job_title: 'Lavozim',
        peace: 'Donalar/Bloklar',
        amount: 'Miqdori',
        notification: 'Bildirishnoma uchun eng past miqdori',
        created_date: 'Yaratilgan sana',
        end_date: 'Tugatilgan sana',
        task: 'Vazifa',
        event: 'Harakatlar',
        create: 'Qo‘shish',
        sum: 'Jami summa',
        date: 'Sana',
        comment: 'Izoh',
        status: 'Holat',
        photo: 'Rasm',
        username: 'Foydalanuvchi nomi:',
        warehouse: 'Ombor',
        excess_allowed: 'Ko‘p tashlash ruxsat berilgan',
        shelf_life: 'Saqlash muddati (kun)',
        currency: 'Valyuta',
        return_date: 'Qaytarilish sanasi',
        repaid: 'To‘lanmagan',
        reason: 'Sabab',
        add_income: 'Kirim qo‘shish',
        currency_account: 'Valyuta hisobi'
    }
}
