<template>
  <v-dialog width="450" v-model="modalState.status">
    <v-card class="modal bg-surface">
      <div class="modal__close">
        <span>{{ title }}</span>
        <font-awesome-icon @click="closeModal()" icon="xmark" style="color: #9497a1" />
      </div>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["modalState", "title"],
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &__close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
      color: #9497a1;
    }
    svg {
      cursor: pointer;
    }
  }
}
</style>
