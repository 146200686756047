export default {
    longitude: 'Longitude',
    latitude: 'Latitude',
    history_work: 'Work History',
    login: 'Log In',
    search: 'Search',
    date: 'Date',
    time: 'Time',
    history: 'History',
    close: 'Close',
    roll_call_status: 'Your response has been successfully saved',
    reason_title: 'Reason Title',
    save_as: 'Save As',
    write_reason: 'Specify the reason',
    save: 'Save',
    right: 'Correct',
    location: 'Location',
    no_call: 'No active roll calls',
    profile: {
        profile: 'Profile',
        firstname: 'Last Name:',
        lastname: 'First Name:',
        phone: 'Phone Number:',
        status: 'Position:',
        username: 'Username:',
        logout: 'Logout'
    },
    sidebar: {
        account_management: 'Account Management',
        dashboard: 'Dashboard',
        clients: 'Clients',
        currency_accounts: 'Currency Accounts',
        finance: 'Finance',
        warehouses: 'Warehouses',
        products: 'Products',
        raw_materials: 'Raw Materials',
        reports: 'Reports',
        debts: 'User Debts',
        salary: 'Salary',
        orders: 'Orders',
        employees: 'Employees',
        tasks: 'Tasks',
        outcome: 'Expenses',
        income: 'Income',
        credit_line: 'Credit Lines',
        loan_repayments: 'Loan Repayments',
        roll_call: 'Roll Call',
        profile: 'Profile'
    },
    table: {
        num: 'No.',
        time: 'Time',
        open: 'Open',
        business_line: 'Business Line',
        fullname: 'Full Name',
        company: 'Company',
        phone_number: 'Phone Number',
        address: 'Address',
        title: 'Title',
        title_product: 'Product Title',
        category: 'Category',
        identifier: 'Identifier',
        recipient: 'Recipient',
        measurement: 'Measurement Units',
        responsible: 'Responsible',
        location: 'Location',
        showmap: 'Show on Map',
        nodata: 'No Data',
        branch_office: 'Branch Offices',
        job_title: 'Job Title',
        peace: 'Pieces/Blocks',
        amount: 'Quantity',
        notification: 'Min. quantity for notifications',
        created_date: 'Creation Date',
        end_date: 'End Date',
        task: 'Task',
        event: 'Actions',
        create: 'Add',
        sum: 'Sum',
        date: 'Date',
        comment: 'Comment',
        status: 'Status',
        photo: 'Photo',
        username: 'Username:',
        warehouse: 'Warehouse',
        excess_allowed: 'Excess Allowed',
        shelf_life: 'Shelf Life (days)',
        currency: 'Currency',
        return_date: 'Return Date',
        repaid: 'Repaid',
        reason: 'Reason',
        add_income: 'Add Income',
        currency_account: 'Currency Account'
    }
}
