<template>
  <div v-if="pageCount" class="pagination">
    <button @click="currentPage--" :disabled="currentPage <= 1" class="prev">
      &#8249;
    </button>
    <vue-awesome-paginate
      v-model="currentPage"
      :max-pages-shown="pageSize"
      :items-per-page="pageSize"
      paginate-buttons-class="btn"
      active-page-class="btn-active"
      back-button-class="back-btn"
      next-button-class="next-btn"
      :total-items="items?.length - 1"
      :on-click="changePage"
      :hide-prev-next="true"
    />
    <button class="next" @click="currentPage++" :disabled="currentPage >= pageCount">
      &#8250;
    </button>
  </div>
</template>
<script>
export default {
  props: ["items"],
  data() {
    return {
      page: 10,
      currentPage: 1,
      pageSize: 5,
      pageCount: 0,
    };
  },
  computed: {
    paginatedItems() {
      this.pageCount = (this.items.length - 1) / this.pageSize;
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.items.slice(start, end);
    },
  },
  watch: {
    items() {
      this.$emit("paginate", this.paginatedItems);
    },
    currentPage() {
      this.$emit("paginate", this.paginatedItems);
    },
  },
  methods: {
    changePage(pageNum) {
      this.currentPage = pageNum;
    },
  },
  mounted() {
    this.$emit("paginate", this.paginatedItems);
  },
};
</script>
