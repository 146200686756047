<template>
  <v-app>
    <div class="mx-0 fill-height d-flex flex main full-width">
      <router-view name="sidebar" />
      <div class="d-flex flex-column full-width content">
        <router-view name="navbar" />
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
import { onMounted, ref } from "vue";
import { useTheme } from "vuetify";
export default {
  inject: ["axios"],
  name: "App",
  setup() {
    const theme = useTheme();
    const selectedTheme = ref("customLightTheme");
    onMounted(() => {
      if (localStorage.getItem("theme")) {
        selectedTheme.value = localStorage.getItem("theme");
        theme.global.name.value = selectedTheme.value;
      }
    });
    return {
      theme,
      selectedTheme,
    };
  },

  mounted() {
    this.axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.getItem("token"));
  },
};
</script>
