import { createStore } from 'vuex';
import currency from './modules/currency'
export default createStore({
    modules: {
        currency
    },
    state: {
        modalCreate: {
            status: false
        },
        isSidebarOpen: false,
        title: null,
        filters: {
            title: null,
            users: [],
            date: null,
            tags: []
        }
    },
    getters: {
        getFilterTitle(state) {
            return state.filters.title;
        },
        getFilterUsers(state) {
            return state.filters.users;
        },
        getFilterTags(state) {
            return state.filters.tags
        },
        getFilterDate(state) {
            return state.filters.date;
        },
        getFilterCategory(state) {
            return state.filters.category;
        },
        getModalCreate(state) {
            return state.modalCreate
        },
        getSidebarState(state) {
            return state.isSidebarOpen;
        },
        getTitle(state) {
            return state.title;
        }
    },
    mutations: {
        toggleModalCreate(state) {
            state.modalCreate.status = !state.modalCreate.status
        },
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        changeTitle(state, item) {
            state.title = item;
        },
        setFiltersTitle(state, value) {
            state.filters.title = value;
        },
        setFiltersUsers(state, value) {
            state.filters.users = value;
        },
        setFiltersTags(state, value) {
            state.filters.tags = value
        }
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar');
        },
        toggleModalCreate({ commit }) {
            commit('toggleModalCreate');
        },
    },
    modules: {}
})